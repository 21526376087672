import { shuffle } from './shuffle';
import Word from './Word';

interface DLXNode {
  left: Node;
  right: Node;
  up: Node;
  down: Node;
  head: Node;
  id: number;
  nodes: number;
  color: number;
  word?: Word;
}

interface NodeConstructorParams {
  left?: Node,
  right?: Node,
}

class Node implements DLXNode {
  left: Node;

  right: Node;

  up: Node;

  down: Node;

  head: Node;

  id: number;

  nodes: number;

  color: number;

  word?: Word;

  constructor({ left, right }: NodeConstructorParams = {}) {
    this.left = left || this;
    this.right = right || this;
    this.up = this;
    this.down = this;
    this.head = this;
    this.id = -1;
    this.nodes = 0;
    this.color = 0;
  }

  cover() {
    for (let rowNode = this.down; rowNode !== this; rowNode = rowNode.down) {
      rowNode.hide();
    }
    this.left.right = this.right;
    this.right.left = this.left;
  }

  hide() {
    for (let other = this.right; other !== this; other = other.right) {
      if (other.color >= 0) {
        other.up.down = other.down;
        other.down.up = other.up;
        other.head.nodes--;
      }
    }
  }

  uncover() {
    this.right.left = this;
    this.left.right = this;
    for (let rowNode = this.up; rowNode !== this; rowNode = rowNode.up) {
      rowNode.unhide();
    }
  }

  unhide() {
    for (let other = this.left; other !== this; other = other.left) {
      if (other.color >= 0) {
        other.up.down = other;
        other.down.up = other;
        other.head.nodes++;
      }
    }
  }

  commit(secondary: Node) {
    if (this.color === 0) {
      secondary.cover();
    } else if (this.color > 0) {
      secondary.purify(this.color);
    }
  }

  uncommit(secondary: Node) {
    if (this.color === 0) {
      secondary.uncover();
    } else if (this.color > 0) {
      secondary.unpurify(this.color);
    }
  }

  purify(targetColor: number) {
    for (let other = this.head.down; other !== this.head; other = other.down) {
      if (other.color === targetColor) { other.color = -1; } else { other.hide(); }
    }
  }

  unpurify(targetColor: number) {
    for (let other = this.head.up; other !== this.head; other = other.up) {
      if (other.color < 0) { other.color = targetColor; } else { other.unhide(); }
    }
  }

  shuffledOptions(rng: () => number): Node[] {
    const rows: Node[] = [];

    for (let row = this.down; row !== this; row = row.down) {
      rows.push(row);
    }

    shuffle(rows, rng);

    return rows;
  }

  sortedOptions(rng: () => number, favorOverlapping: boolean): Node[] {
    const rows = this.shuffledOptions(rng);

    rows.sort((a: Node, b: Node): number => {
      let aOverlaps = 0;
      for (let node = a.right; node !== a; node = node.right) {
        if (node.color === -1) { aOverlaps++; }
      }

      let bOverlaps = 0;
      for (let node = b.right; node !== b; node = node.right) {
        if (node.color === -1) { bOverlaps++; }
      }


      return favorOverlapping ? bOverlaps - aOverlaps : aOverlaps - bOverlaps;
    });

    return rows;
  }

  prune() {
    for (let node = this.right; node !== this; node = node.right) {
      node.commit(node.head);
    }
  }

  unprune() {
    for (let node = this.left; node !== this; node = node.left) {
      node.uncommit(node.head);
    }
  }
}

export default Node;
