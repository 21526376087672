
import React, { useContext } from 'react';
import { Select, Heading, Flex } from '@chakra-ui/core';
import { context } from '../../../puzzle';
import FitHandler from '../../../puzzle/FitHandler';

const convert = (value: string): FitHandler => parseInt(value, 10);

export default function FitHandlerSelect() {
  const { state, dispatch } = useContext(context);

  return (
    <>
      <Heading textAlign="center" size="sm" paddingBottom="2px">Autofit Strategy</Heading>
      <Flex alignContent="center">
        <Select
          variant="filled"
          defaultValue={state.fitHandler}
          onChange={(e) => dispatch({ action: 'SET_FIT_HANDLER', data: convert(e.target.value) })}
        >
          <option value={FitHandler.INCREASE_SIZE}>Increase Size</option>
          <option value={FitHandler.REMOVE_WORD}>Remove Words</option>
        </Select>
      </Flex>
    </>
  );
}
