import { theme } from '@chakra-ui/core';

export default {
  ...theme,
  fonts: {
    heading: 'Raleway, sans-serif',
    body: 'Lato, system-ui, sans-serif',
    mono: 'Source Code Pro, Menlo, monospace',
  },
};
