import React from 'react';
import {
  Box,
  Heading,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/core';
import Title from './Title';
import Dimensions from './Dimensions';
import WordList from './WordList';
import DisplayOptions from './display_options';
import AdvancedOptions from './advanced_options';

export default function Settings() {
  return (
    <Box>
      <Heading
        textAlign="center"
        size="md"
        paddingBottom="4px"
      >
        Edit Puzzle
      </Heading>
      <Title />
      <Dimensions />
      <Accordion allowMultiple paddingBottom="1em" paddingTop="1em">
        <AccordionItem>
          <AccordionHeader>
            <Box flex="1" textAlign="center">
              <Heading size="sm">Display Options</Heading>
            </Box>
            <AccordionIcon />
          </AccordionHeader>
          <AccordionPanel>
            <DisplayOptions />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader>
            <Box flex="1" textAlign="center">
              <Heading size="sm">Advanced Puzzle Options</Heading>
            </Box>
            <AccordionIcon />
          </AccordionHeader>
          <AccordionPanel>
            <AdvancedOptions />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <WordList />
    </Box>
  );
}
