import React, { useContext } from 'react';
import {
  Input, InputGroup, InputLeftAddon,
} from '@chakra-ui/core';
import { context } from '../../../puzzle';
import { OnChangeEvent } from '../../../OnChangeEvent';

export default function Seed() {
  const { state, dispatch } = useContext(context);
  return (
    <InputGroup>
      <InputLeftAddon>Seed</InputLeftAddon>
      <Input
        variant="filled"
        type="text"
        placeholder="Seed"
        value={state.seed}
        onChange={(e: OnChangeEvent) => dispatch({ action: 'SET_SEED', data: e.target.value })}
      />
    </InputGroup>
  );
}
