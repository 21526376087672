import { Direction, move } from '../Direction';

interface WordAttributes {
  string: string;
  startingRow: number;
  startingColumn: number;
  direction: Direction;
}

export type LetterCoordinate = {
  letter: string;
  row: number;
  column: number;
};

export default class Word implements WordAttributes {
  string: string;

  startingRow: number;

  startingColumn: number;

  direction: Direction;

  constructor({
    string, startingRow, startingColumn, direction,
  }: WordAttributes) {
    this.string = string;
    this.startingRow = startingRow;
    this.startingColumn = startingColumn;
    this.direction = direction;
  }

  get length() {
    return this.string.length;
  }

  letterCoordinates(): LetterCoordinate[] {
    return Array.from(this.string).map((letter, i) => {
      const { deltaRows, deltaColumns } = move({ direction: this.direction, steps: i });
      return {
        letter,
        row: this.startingRow + deltaRows,
        column: this.startingColumn + deltaColumns,
      };
    });
  }

  toString() {
    return this.string;
  }
}
