import React, { useContext, useState, useEffect } from 'react';
import { Select, FormControl, FormLabel } from '@chakra-ui/core';
import { context } from '../../../puzzle';
import 'whatwg-fetch';

const GOOGLE_FONT_API = 'AIzaSyCK-8pD3C2NB5ixww4yo4k0BI-5MNn_VXA';

interface FontSelectProps {
  label: string;
  value: 'titleFont' | 'puzzleFont';
  changeAction: 'SET_TITLE_FONT' | 'SET_PUZZLE_FONT';
}

type FontOptions = Map<string, string[]>;
interface GoogleFontItem {
  family: string,
  category: string,
}

export default function FontSelect({ label, value, changeAction }: FontSelectProps) {
  const { state, dispatch } = useContext(context);
  const [fontOptions, setFontOptions] = useState<FontOptions>(new Map());

  useEffect(() => {
    fetch(
      `https://www.googleapis.com/webfonts/v1/webfonts?key=${GOOGLE_FONT_API}&sort=popularity`,
      { mode: 'cors', credentials: 'omit' },
    ).then(
      (response) => response.json(),
    ).then(
      ({ items }) => setFontOptions(
        items.reduce(
          (options: FontOptions, { family, category }: GoogleFontItem) => {
            if (options.has(category)) {
          options.get(category)?.push(family);
            } else {
              options.set(category, [family]);
            }

            return options;
          },
          new Map(),
        ),
      ),
    );
  }, []);

  return (
    <FormControl>
      <FormLabel htmlFor={label}>{label}</FormLabel>
      <Select
        id={label}
        variant="filled"
        value={state[value]}
        onChange={(e) => dispatch({ action: changeAction, data: e.target.value })}
      >
        {Array.from(fontOptions.entries()).map(([category, fonts]) => (
          <optgroup key={category} label={category}>
            {fonts.map((font) => (
              <option key={font} value={font}>{font}</option>
            ))}
          </optgroup>
        ))}
      </Select>
    </FormControl>
  );
}
