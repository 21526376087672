/* eslint-disable no-restricted-syntax */
import Node from './Node';
import Matrix, { Constraints } from './Matrix';
import Word from './Word';
import FavorOverlap from '../FavorOverlap';

type Solution = Node[];

export default class Solver {
  matrix: Matrix;

  rng: () => number;

  favorOverlap: FavorOverlap;

  constructor(constraints: Constraints, rng: () => number, favorOverlap: FavorOverlap) {
    this.matrix = Matrix.fromConstraints(constraints);
    this.rng = rng;
    this.favorOverlap = favorOverlap;
  }

  get head() {
    return this.matrix.head;
  }

  placeWords(): Word[] | undefined {
    const solution: Solution = [];
    if (this.xcc(solution)) {
      return solution.filter((option) => !!option.word).map((option) => option.word as Word);
    }

    return undefined;
  }

  private xcc(solution: Solution): boolean {
    let foundSolution = false;

    if (this.head.right === this.head) { return true; }

    const item = this.sparsestItem();
    if (!item) { return false; }

    item.cover();
    for (const option of this.optionsForItem(item)) {
      solution.push(option);
      option.prune();
      foundSolution = this.xcc(solution);
      option.unprune();
      if (foundSolution) { break; }
      solution.pop();
    }
    item.uncover();
    return foundSolution;
  }

  private optionsForItem(item: Node) {
    if (this.favorOverlap === FavorOverlap.SOMETIMES) {
      return item.shuffledOptions(this.rng);
    }
    return item.sortedOptions(this.rng, this.favorOverlap === FavorOverlap.MORE);
  }

  private sparsestItem(): Node | undefined {
    let sparsest;

    for (let node = this.head.right; node !== this.head; node = node.right) {
      if (node.nodes > 0 && (!sparsest || node.nodes < sparsest.nodes)) {
        sparsest = node;
      }
    }

    return sparsest;
  }
}
