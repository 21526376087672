/* eslint-disable no-restricted-syntax */
import Node from './Node';
import Word, { LetterCoordinate } from './Word';
import PossiblePlacements from './PossiblePlacements';
import { Direction } from '../Direction';
import { incrementor } from './counter';

export type Constraints = {
  wordList: string[];
  rowCount: number;
  columnCount: number;
  allowedDirections: Direction[];
};

const letterCoordinateKey = ({ row, column }: LetterCoordinate): string => `${column},${row}`;
const colorForLetter = (letter: string): number => letter.toUpperCase().charCodeAt(0) - 65 + 1;
// const nonOverlappingColor = (): number => incrementor.next();

export default class Matrix {
  head: Node;

  private secondaryHead: Node;

  private labelToNode: Map<string, Node>;

  static fromConstraints(constraints: Constraints): Matrix {
    incrementor.reset();
    const options = constraints.wordList.flatMap(
      (word) => PossiblePlacements.for({
        word,
        columns: constraints.columnCount,
        rows: constraints.rowCount,
        directions: constraints.allowedDirections,
      }),
    );

    const matrix = new Matrix();

    for (const option of options) {
      matrix.addOption(option);
    }

    return matrix;
  }

  constructor() {
    this.head = new Node();
    this.secondaryHead = new Node();
    this.labelToNode = new Map();
  }

  private addOption(option: Word) {
    const items = [this.itemForLabel(option.string)];
    const colors = [0];
    for (const letterCoordinate of option.letterCoordinates()) {
      items.push(this.secondaryItemForLabel(letterCoordinateKey(letterCoordinate)));
      colors.push(colorForLetter(letterCoordinate.letter));
      // colors.push(nonOverlappingColor());
    }

    for (
      let currentNode = new Node(), previousNode = currentNode, firstNode = currentNode, i = 0;
      i < items.length;
      previousNode = currentNode, currentNode = new Node(), i++
    ) {
      const header = items[i];
      const color = colors[i];

      currentNode.down = header;
      currentNode.up = header.up;
      currentNode.left = previousNode;
      currentNode.right = firstNode;
      currentNode.head = header;
      currentNode.color = color;
      currentNode.word = option;

      previousNode.right = currentNode;
      header.up.down = currentNode;
      header.up = currentNode;
      header.nodes++;
    }
  }

  secondaryItemForLabel(label: string): Node {
    if (this.labelToNode.has(label)) {
      return this.labelToNode.get(label) as Node;
    }

    const node = new Node({ left: this.secondaryHead.left, right: this.secondaryHead });
    this.secondaryHead.left.right = node;
    this.secondaryHead.left = node;
    this.labelToNode.set(label, node);

    return node;
  }

  itemForLabel(label: string): Node {
    if (this.labelToNode.has(label)) {
      return this.labelToNode.get(label) as Node;
    }

    const node = new Node({ left: this.head.left, right: this.head });
    this.head.left.right = node;
    this.head.left = node;
    this.labelToNode.set(label, node);

    return node;
  }
}
