import React from 'react';
import { Divider } from '@chakra-ui/core';
import PossibleDirections from './PossibleDirections';
import FavorOverlap from './FavorOverlap';
import FitHandlerSelect from './FitHandlerSelect';
import Seed from './Seed';

export default function AdvancedOptions() {
  return (
    <>
      <PossibleDirections />
      <Divider />
      <FavorOverlap />
      <Divider />
      <FitHandlerSelect />
      <Divider />
      <Seed />
    </>
  );
}
