import React from 'react';
import { Flex, FormLabel, Switch } from '@chakra-ui/core';

interface ToggleAnswersProps {
  onChange: () => void,
  selected: boolean,
}

export default function ToggleAnswers({ onChange, selected }: ToggleAnswersProps) {
  return (
    <Flex paddingTop="1em" justify="center" align="center">
      <FormLabel htmlFor="display-answers">Show Solution</FormLabel>
      <Switch
        id="display-answers"
        isChecked={selected}
        onChange={onChange}
      />
    </Flex>
  );
}
