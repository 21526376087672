import React from 'react';
import { Divider } from '@chakra-ui/core';
import FontSelect from './FontSelect';
import DisplayBorder from './DisplayBorder';
import WordListPositionSelect from './WordListPositionSelect';

export default function DisplayOptions() {
  return (
    <>
      <FontSelect label="Title Font" value="titleFont" changeAction="SET_TITLE_FONT" />
      <Divider />
      <FontSelect label="Puzzle Font" value="puzzleFont" changeAction="SET_PUZZLE_FONT" />
      <Divider />
      <DisplayBorder />
      <Divider />
      <WordListPositionSelect />
    </>
  );
}
