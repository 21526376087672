import React, { useContext } from 'react';
import {
  Flex, Box, IconButton, Heading,
} from '@chakra-ui/core';
import {
  FiArrowDownLeft,
  FiArrowDownRight,
  FiArrowDown,
  FiArrowLeft,
  FiArrowRight,
  FiArrowUpLeft,
  FiArrowUpRight,
  FiArrowUp,
  FiCircle,
} from 'react-icons/fi';
import { context } from '../../../puzzle';
import { allDirections, Direction } from '../../../Direction';

export default function PossibleDirections() {
  const { state, dispatch } = useContext(context);
  const variantColor = (direction: Direction) => (state.directions.has(direction) ? 'green' : 'gray');
  const buttonData = [
    [
      { label: 'Northwest', icon: FiArrowUpLeft, direction: Direction.Northwest },
      { label: 'North', icon: FiArrowUp, direction: Direction.North },
      { label: 'Northeast', icon: FiArrowUpRight, direction: Direction.Northeast },
    ],
    [
      { label: 'West', icon: FiArrowLeft, direction: Direction.West },
      { label: 'All', icon: FiCircle, direction: Direction.Northwest },
      { label: 'East', icon: FiArrowRight, direction: Direction.East },
    ],
    [
      { label: 'Southwest', icon: FiArrowDownLeft, direction: Direction.Southwest },
      { label: 'South', icon: FiArrowDown, direction: Direction.South },
      { label: 'Southeast', icon: FiArrowDownRight, direction: Direction.Southeast },
    ],
  ];
  return (
    <Box>
      <Heading textAlign="center" size="sm">Possible Directions</Heading>
      <Flex pt={4} justify="center">
        <Box>
          {buttonData.map((row, rowIndex) => (
            <Box key={rowIndex}>
              {row.map((button) => {
                if (button.label === 'All') {
                  const directions = state.directions.size < 8
                    ? allDirections()
                    : new Set<Direction>();
                  return (
                    <IconButton
                      key={button.label}
                      aria-label={button.label}
                      icon={button.icon}
                      onClick={() => dispatch({ action: 'SET_DIRECTIONS', data: directions })}
                    />
                  );
                }
                return (
                  <IconButton
                    key={button.label}
                    aria-label={button.label}
                    icon={button.icon}
                    onClick={() => dispatch({ action: 'TOGGLE_DIRECTION', data: button.direction })}
                    variantColor={variantColor(button.direction)}
                  />
                );
              })}
            </Box>
          ))}
        </Box>
      </Flex>
    </Box>
  );
}
