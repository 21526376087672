/* eslint-disable no-restricted-syntax */
import React, { useContext } from 'react';
import { alea } from 'seedrandom';
import { useTheme } from '@chakra-ui/core';
import { context } from '../../puzzle';
import Word from '../../wordsearch-builder/Word';
import { Direction } from '../../Direction';

interface Cell {
  letter: string;
  solutionFor?: string;
}

const ALPHABET = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ');

const randomLetter = (rng: () => number) => ALPHABET[Math.floor(rng() * ALPHABET.length)];

function fillGrid(rows: number, columns: number, words: Word[], seed: string): Cell[][] {
  const rng = alea(seed);
  const grid = new Array(rows);
  for (let i = 0; i < rows; ++i) {
    grid[i] = new Array(columns);
  }

  for (const word of words) {
    for (const { letter, row, column } of word.letterCoordinates()) {
      grid[row][column] = { letter, solutionFor: word.string };
    }
  }

  for (const row of grid) {
    for (let i = 0; i < row.length; ++i) {
      if (!row[i]) {
        row[i] = { letter: randomLetter(rng) };
      }
    }
  }

  return grid;
}

interface GridParams {
  displaySolution?: boolean;
}

export default function Grid({ displaySolution = false }: GridParams) {
  const { state } = useContext(context);
  const theme = useTheme();

  const grid = fillGrid(state.rows, state.columns, state.wordList.map((word) => new Word({
    string: word.word,
    startingColumn: word.position?.column as number,
    startingRow: word.position?.row as number,
    direction: word.position?.direction as Direction,
  })), state.seed);

  const gridStyle = {
    fontSize: 18,
    fontFamily: state.puzzleFont,
    border: state.displayBorder ? '1px solid black' : undefined,
    padding: '0.5em',
  };

  return (
    <table style={gridStyle}>
      <tbody>
        {grid.map((row: Cell[], y) => (
          <tr key={y}>
            {row.map((cell: Cell, x) => (
              <td
                key={`${x},${y}`}
                style={{
                  width: '1.5em',
                  height: '1.5em',
                  backgroundColor: (displaySolution && cell.solutionFor)
                    ? theme.colors.yellow[100]
                    : undefined,
                  textAlign: 'center',
                  fontWeight: (displaySolution && cell.solutionFor) ? 'bolder' : undefined,
                }}
              >
                {cell.letter}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
