export enum Direction {
  Northwest,
  North,
  Northeast,
  West,
  East,
  Southwest,
  South,
  Southeast
}
export function allDirections(): Set<Direction> {
  return new Set([
    Direction.Northwest,
    Direction.North,
    Direction.Northeast,
    Direction.West,
    Direction.East,
    Direction.Southwest,
    Direction.South,
    Direction.Southeast,
  ]);
}

export function move(
  { direction, steps }: { direction: Direction, steps: number },
): { deltaRows: number, deltaColumns: number } {
  switch (direction) {
    case Direction.North:
      return { deltaRows: -steps, deltaColumns: 0 };
    case Direction.South:
      return { deltaRows: steps, deltaColumns: 0 };
    case Direction.East:
      return { deltaColumns: steps, deltaRows: 0 };
    case Direction.West:
      return { deltaColumns: -steps, deltaRows: 0 };
    case Direction.Northeast:
      return { deltaRows: -steps, deltaColumns: steps };
    case Direction.Southeast:
      return { deltaColumns: steps, deltaRows: steps };
    case Direction.Southwest:
      return { deltaRows: steps, deltaColumns: -steps };
    case Direction.Northwest:
      return { deltaColumns: -steps, deltaRows: -steps };
    default:
      return { deltaColumns: 0, deltaRows: 0 };
  }
}

export default Direction;
