import React, { useContext, ReactText } from 'react';
import {
  Flex, FormControl, FormLabel, NumberInput, Divider,
} from '@chakra-ui/core';
import { context } from '../../puzzle';

export default function Dimensions() {
  const { state, dispatch } = useContext(context);
  const currentMin = Math.max(...state.wordList.map(({ word }) => word.length), 2);

  return (
    <Flex paddingTop={2} align="center">
      <FormControl>
        <FormLabel htmlFor="rows">
          Rows
        </FormLabel>
        <NumberInput
          id="rows"
          min={state.columns >= currentMin ? 1 : currentMin}
          value={state.rows}
          onChange={(e: ReactText) => dispatch({ action: 'SET_ROWS', data: e as number })}
        />
      </FormControl>
      <Divider orientation="vertical" />
      <FormControl>
        <FormLabel htmlFor="columns">
          Columns
        </FormLabel>
        <NumberInput
          id="columns"
          min={state.rows >= currentMin ? 1 : currentMin}
          value={state.columns}
          onChange={(e: ReactText) => dispatch({ action: 'SET_COLUMNS', data: e as number })}
        />
      </FormControl>
    </Flex>
  );
}
