import React, { useContext } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { context } from './puzzle';

export default function LoadState() {
  const { encodedState } = useParams();
  const { dispatch } = useContext(context);

  if (encodedState) {
    dispatch({ action: 'DECODE_STATE', data: encodedState });
  }

  return <Redirect to="/" />;
}
