/* eslint-disable max-classes-per-file */
class Incrementor {
  from: number;

  n: number;

  constructor(from = 0) {
    this.from = from;
    this.n = from;
  }

  next(): number {
    return ++this.n;
  }

  reset(): number {
    this.n = this.from;
    return this.n;
  }
}

export const incrementor = new Incrementor();

class Decrementor {
  from: number;

  n: number;

  constructor(from = 0) {
    this.from = from;
    this.n = from;
  }

  next(): number {
    return --this.n;
  }

  reset(): number {
    this.n = this.from;
    return this.n;
  }
}

export const decrementor = new Decrementor();
