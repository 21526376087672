import utf8 from 'utf8';
import base64 from 'base-64';
import { allDirections, Direction } from './Direction';
import FitHandler from './puzzle/FitHandler';
import WordListPosition from './puzzle/WordListPosition';
import Status from './puzzle/Status';
import FavorOverlap from './FavorOverlap';

interface Position {
  row: number;
  column: number;
  direction: Direction;
}

interface WordListEntry {
  word: string,
  position?: Position,
}

interface StateFields {
  title: string;
  rows: number;
  columns: number;
  directions: Set<Direction>;
  wordList: WordListEntry[];
  titleFont: string;
  puzzleFont: string;
  fitHandler: FitHandler;
  seed: string;
  wordListPosition: WordListPosition;
  status: Status;
  displayBorder: boolean;
  favorOverlap: FavorOverlap;
}


export default class State implements StateFields {
  title: string;

  rows: number;

  columns: number;

  directions: Set<Direction>;

  wordList: WordListEntry[];

  titleFont: string;

  puzzleFont: string;

  fitHandler: FitHandler;

  seed: string;

  wordListPosition: WordListPosition;

  status: Status;

  displayBorder: boolean;

  favorOverlap: FavorOverlap;

  constructor({
    title,
    rows,
    columns,
    directions,
    wordList,
    titleFont,
    puzzleFont,
    fitHandler,
    seed,
    wordListPosition,
    status,
    displayBorder,
    favorOverlap,
  }: StateFields) {
    this.title = title;
    this.rows = rows;
    this.columns = columns;
    this.directions = directions;
    this.wordList = wordList;
    this.titleFont = titleFont;
    this.puzzleFont = puzzleFont;
    this.fitHandler = fitHandler;
    this.seed = seed;
    this.wordListPosition = wordListPosition;
    this.status = status;
    this.displayBorder = displayBorder;
    this.favorOverlap = favorOverlap;
  }

  static default(): State {
    return new State({
      title: '',
      rows: 15,
      columns: 15,
      directions: allDirections(),
      wordList: [],
      titleFont: 'Raleway',
      puzzleFont: 'Lato',
      fitHandler: FitHandler.INCREASE_SIZE,
      seed: Date.now().toString(),
      wordListPosition: WordListPosition.BOTTOM,
      status: Status.PENDING,
      displayBorder: false,
      favorOverlap: FavorOverlap.SOMETIMES,
    });
  }

  static decode(dataString: string): State {
    const bytes = base64.decode(dataString);
    const jsonString = utf8.decode(bytes);
    const fields = JSON.parse(jsonString);

    return new State({ ...fields, directions: new Set(fields.directions) });
  }

  encode(): string {
    const jsonString = JSON.stringify({ ...this, directions: Array.from(this.directions) });
    const bytes = utf8.encode(jsonString);
    return base64.encode(bytes);
  }
}
