/* eslint-disable no-restricted-syntax */
import { Direction, move } from '../Direction';
import Word from './Word';

export interface Position { column: number, row: number }
export type Placement = [ string, Position ];

interface PossiblePlacementsParams {
  word: string,
  columns: number,
  rows: number,
  directions: Direction[],
}

export default class PossiblePlacements {
  static for(params: PossiblePlacementsParams): Word[] {
    const possiblePlacements = new PossiblePlacements(params);
    return possiblePlacements.findAll();
  }

  word: string;

  columns: number;

  rows: number;

  directions: Direction[];

  constructor({
    word, columns, rows, directions,
  }: PossiblePlacementsParams) {
    this.word = word;
    this.columns = columns;
    this.rows = rows;
    this.directions = directions;
  }

  findAll(): Word[] {
    const words: Word[] = [];

    for (let row = 0; row < this.rows; ++row) {
      for (let column = 0; column < this.columns; ++column) {
        for (const direction of this.directions) {
          if (this.isPossible({ row, column, direction })) {
            words.push(new Word({
              string: this.word, startingRow: row, startingColumn: column, direction,
            }));
          }
        }
      }
    }

    return words;
  }

  isPossible(
    { row, column, direction }: { row: number, column: number, direction: Direction },
  ): boolean {
    const { deltaRows, deltaColumns } = move({ direction, steps: this.word.length - 1 });
    return row + deltaRows < this.rows
      && row + deltaRows >= 0
      && column + deltaColumns < this.columns
      && column + deltaColumns >= 0;
  }
}
