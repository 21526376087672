import React from 'react';
import { ThemeProvider, CSSReset } from '@chakra-ui/core';
import {
  Switch,
  Route,
} from 'react-router-dom';
import theme from './theme';
import MainPage from './main_page';
import PrintPage from './print_page';
import { PuzzleProvider } from './puzzle';
import LoadState from './LoadState';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <PuzzleProvider>
        <CSSReset />
        <Switch>
          <Route path="/print">
            <PrintPage />
          </Route>
          <Route path="/:encodedState">
            <LoadState />
          </Route>
          <Route exact path="/">
            <MainPage />
          </Route>
        </Switch>
      </PuzzleProvider>
    </ThemeProvider>
  );
}

export default App;
