import React, { useContext } from 'react';
import { Input, FormControl, FormLabel } from '@chakra-ui/core';
import { context } from '../../puzzle';
import { OnChangeEvent } from '../../OnChangeEvent';

export default function Title() {
  const { state, dispatch } = useContext(context);
  return (
    <FormControl>
      <FormLabel htmlFor="title">Title</FormLabel>
      <Input
        id="title"
        variant="flushed"
        type="text"
        placeholder="Your Title Here"
        value={state.title}
        onChange={(e: OnChangeEvent) => dispatch({ action: 'SET_TITLE', data: e.target.value })}
      />
    </FormControl>
  );
}
