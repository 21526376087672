import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { context } from '../puzzle';

const encodedLink = (encoding: string) => new URL(encoding, window.location.origin).toString();


export default function WindowTitle() {
  const { state } = useContext(context);

  return (
    <Helmet titleTemplate="%s | Planet Word Find" defaultTitle="Planet Word Find">
      <meta
        property="og:title"
        content={state.title.length > 0 ? state.title : 'Planet Word Find'}
      />
      <meta property="og:url" content={encodedLink(state.encode())} />
      {state.title.length > 0 && <title>{state.title}</title>}
    </Helmet>
  );
}
