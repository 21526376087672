import React, { useContext } from 'react';
import { Heading, Select } from '@chakra-ui/core';
import { context } from '../../../puzzle';
import WordListPosition from '../../../puzzle/WordListPosition';

const convert = (value: string): WordListPosition => parseInt(value, 10);

export default function WordListPositionSelect() {
  const { state, dispatch } = useContext(context);

  return (
    <>
      <Heading size="sm" paddingBottom="2px">Display Word List</Heading>
      <Select
        variant="filled"
        onChange={(e) => dispatch({ action: 'SET_WORD_LIST_POSITION', data: convert(e.target.value) })}
        value={state.wordListPosition}
      >
        <option value={WordListPosition.NONE}>None</option>
        <option value={WordListPosition.BOTTOM}>Bottom</option>
        <option value={WordListPosition.TOP}>Top</option>
        <option value={WordListPosition.LEFT}>Left</option>
        <option value={WordListPosition.RIGHT}>Right</option>
      </Select>
    </>
  );
}
