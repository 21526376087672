import React from 'react';
import { Button } from '@chakra-ui/core';
import { FiPrinter } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';

export default function Print() {
  return (
    <RouterLink to="/print">
      <Button rightIcon={FiPrinter} variant="outline" variantColor="blue">
        Print
      </Button>
    </RouterLink>
  );
}
