import React, { useContext } from 'react';
import { useClipboard, Button } from '@chakra-ui/core';
import { FiShare2 } from 'react-icons/fi';
import { context } from '../../puzzle';

const shareLink = (encoding: string) => new URL(encoding, window.location.origin).toString();

export default function Share() {
  const { state } = useContext(context);
  const { onCopy, hasCopied } = useClipboard(shareLink(state.encode()));

  return (
    <Button rightIcon={FiShare2} variant="outline" variantColor="blue" onClick={onCopy}>
      {hasCopied ? 'Copied' : 'Sharable Link'}
    </Button>
  );
}
