import React, { useContext, useState } from 'react';
import {
  Alert, AlertIcon, Flex, Heading, Box, Spinner,
} from '@chakra-ui/core';
import { context } from '../../puzzle';
import Status from '../../puzzle/Status';
import Grid from './Grid';
import WordListPosition from '../../puzzle/WordListPosition';
import WordlistDisplay from './WordlistDisplay';
import Share from './Share';
import loadFonts from '../../loadFonts';
import ToggleAnswers from './ToggleAnswers';
import Print from './Print';

interface WordSearchProps {
  displaySolution?: boolean;
}

const WordSearch = ({ displaySolution }: WordSearchProps) => {
  const { state } = useContext(context);

  switch (state.status) {
    case Status.COMPLETE:
      return (
        <Grid displaySolution={displaySolution} />
      );
    case Status.FAILURE:
      return (
        <Alert status="error">
          <AlertIcon />
          There was an error generating your puzzle.
          Try adjusting your settings and it will attempt to generate a new one.
        </Alert>
      );
    case Status.PENDING:
      return null;
    case Status.GENERATING:
      return (
        <Box paddingTop="32px">
          <Spinner
            color="blue"
          />
        </Box>
      );
    default:
      return null;
  }
};

interface PuzzleDisplayProps {
  interactive?: boolean;
}

export default function PuzzleDisplay({ interactive = false }: PuzzleDisplayProps) {
  const [displaySolution, setDisplaySolution] = useState(false);
  const { state } = useContext(context);
  const flexDirection = () => {
    switch (state.wordListPosition) {
      case WordListPosition.LEFT:
      case WordListPosition.RIGHT:
        return 'row';
      case WordListPosition.BOTTOM:
      case WordListPosition.TOP:
      default:
        return 'column';
    }
  };
  const columns = () => {
    switch (state.wordListPosition) {
      case WordListPosition.LEFT:
      case WordListPosition.RIGHT:
        return 2;
      case WordListPosition.BOTTOM:
      case WordListPosition.TOP:
      default:
        return 4;
    }
  };
  loadFonts([state.titleFont, state.puzzleFont]);
  return (
    <Box>
      <Heading textAlign="center" size="2xl" fontFamily={state.titleFont} paddingTop="1em" paddingBottom="0.5em">{state.title}</Heading>
      <Flex alignItems="center" justifyContent="center" direction={flexDirection()}>
        {(state.wordListPosition === WordListPosition.LEFT
          || state.wordListPosition === WordListPosition.TOP)
          && (
          <WordlistDisplay columns={columns()} />
          )}
        <WordSearch displaySolution={displaySolution} />
        {(state.wordListPosition === WordListPosition.RIGHT
          || state.wordListPosition === WordListPosition.BOTTOM)
          && (
          <WordlistDisplay columns={columns()} />
          )}
      </Flex>
      {interactive && (
        <Flex paddingTop="0.5em" alignItems="center" direction="column">
          <Flex justifyContent="space-evenly" width="33%">
            <Share />
            <Print />
          </Flex>
          <ToggleAnswers
            onChange={() => setDisplaySolution(!displaySolution)}
            selected={displaySolution}
          />
        </Flex>
      )}
    </Box>
  );
}
