import React from 'react';
import {
  Grid, Box, Flex, Image, Text,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import Settings from './settings';
import PuzzleDisplay from './puzzle_display';
import Logo from './logo.svg';
import WindowTitle from './WindowTitle';

export default function MainPage() {
  return (
    <>
      <WindowTitle />
      <Grid
        height="100vh"
        templateAreas="'settings puzzle puzzle' 'footer footer footer'"
        templateColumns="repeat(3, 1fr)"
        templateRows="auto 3em"
      >
        <Box
          height="100%"
          style={{ gridArea: 'settings' }}
          borderRightWidth="1px"
          borderRightColor="gray"
          padding={2}
        >
          <Settings />
        </Box>
        <Box style={{ gridArea: 'puzzle' }}>
          <PuzzleDisplay interactive />
        </Box>
        <Flex
          as="nav"
          justify="space-between"
          alignItems="center"
          style={{ gridArea: 'footer' }}
          borderTopWidth="1px"
          borderTopColor="gray"
        >
          <Flex direction="row" alignItems="center">
            <Link to="/">
              <Image display="inline" src={Logo} alt="logo" height="3em" paddingLeft="1em" />
              <Text display="inline" size="sm" paddingLeft="0.5em">Planet Word Find</Text>
            </Link>
          </Flex>
        </Flex>
      </Grid>
    </>
  );
}
