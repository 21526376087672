import React, { useContext } from 'react';
import { List, ListItem } from '@chakra-ui/core';
import { context } from '../../puzzle';

interface WordlistDisplayParams {
  columns?: number;
}

export default function WordlistDisplay({ columns = 3 }: WordlistDisplayParams) {
  const { state } = useContext(context);

  return (
    <List style={{ columns }} padding="2em">
      {state.wordList.sort().map(({ word }, i) => (
        <ListItem
          key={i}
          fontFamily={state.puzzleFont}
        >
          {word}
        </ListItem>
      ))}
    </List>
  );
}
