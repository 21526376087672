import WebFont from 'webfontloader';

const LOADED_FONTS = new Set<string>();

export default function loadFonts(fontFamilies: string[]) {
  const families = fontFamilies.filter((family) => !LOADED_FONTS.has(family));

  if (families.length > 0) {
    WebFont.load({
      google: {
        families,
      },
    });

    families.forEach((family) => LOADED_FONTS.add(family));
  }
}
