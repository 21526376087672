/* eslint-disable import/prefer-default-export */
import React, {
  Dispatch, createContext, ReactNode, useReducer, useEffect, useRef,
} from 'react';
import { alea } from 'seedrandom';
import State from '../State';
import Action from './Action';
import FitHandler from './FitHandler';
import Solver from '../wordsearch-builder/Solver';
import Word from '../wordsearch-builder/Word';
import Status from './Status';

interface PuzzleContext {
  state: State;
  dispatch: Dispatch<Action>;
}

export const context = createContext<PuzzleContext>(
  { state: State.default(), dispatch: () => { } },
);
const { Provider } = context;

function reducer(state: State, action: Action): State {
  switch (action.action) {
    case 'SET_TITLE':
      return new State({ ...state, title: action.data });
    case 'SET_TITLE_FONT':
      return new State({ ...state, titleFont: action.data });
    case 'SET_PUZZLE_FONT':
      return new State({ ...state, puzzleFont: action.data });
    case 'SET_WORD_LIST_POSITION':
      return new State({ ...state, wordListPosition: action.data });
    case 'SET_FIT_HANDLER':
      return new State({ ...state, fitHandler: action.data });
    case 'REMOVE_WORD':
      return new State({
        ...state,
        wordList: state.wordList.filter(({ word }) => word !== action.data),
      });
    case 'TOGGLE_DIRECTION': {
      const directions = new Set(state.directions);
      if (directions.has(action.data)) {
        directions.delete(action.data);
      } else {
        directions.add(action.data);
      }
      return new State({ ...state, directions, status: Status.PENDING });
    }
    case 'SET_DIRECTIONS':
      return new State({ ...state, directions: action.data, status: Status.PENDING });
    case 'SET_SEED':
      return new State({ ...state, seed: action.data, status: Status.PENDING });
    case 'SET_COLUMNS':
      return new State({ ...state, columns: action.data, status: Status.PENDING });
    case 'SET_ROWS':
      return new State({ ...state, rows: action.data, status: Status.PENDING });
    case 'ADD_WORDS': {
      const words = Array.from(
        new Set(state.wordList.map(({ word }) => word).concat(action.data)),
      );
      return new State({
        ...state,
        wordList: words.map((word) => ({ word })),
        status: Status.PENDING,
      });
    }
    case 'SET_STATUS':
      return new State({ ...state, status: action.data });
    case 'TOGGLE_DISPLAY_BORDER':
      return new State({ ...state, displayBorder: !state.displayBorder });
    case 'PLACE_WORDS':
      return new State({
        ...state,
        status: Status.COMPLETE,
        wordList: action.data.map(({
          string, startingRow, startingColumn, direction,
        }: Word) => ({
          word: string,
          position: {
            row: startingRow,
            column: startingColumn,
            direction,
          },
        })),
      });
    case 'SET_FAVOR_OVERLAP':
      return new State({
        ...state, favorOverlap: action.data, status: Status.PENDING,
      });
    case 'DECODE_STATE':
      return State.decode(action.data);
    default:
      return state;
  }
}

interface PuzzleProviderProps {
  children: ReactNode;
  delay?: number;
  encodedState?: string;
}

export function PuzzleProvider({ children, delay = 500 }: PuzzleProviderProps) {
  const initialState = State.default();

  const [state, dispatch] = useReducer(reducer, initialState);
  const timeoutId: React.MutableRefObject<NodeJS.Timeout | undefined> = useRef();

  const {
    rows, columns, wordList, directions, seed, fitHandler, status, favorOverlap,
  } = state;

  useEffect(() => {
    if (status === Status.PENDING) {
      clearTimeout(timeoutId.current as NodeJS.Timeout);
      timeoutId.current = setTimeout(() => {
        dispatch({ action: 'SET_STATUS', data: Status.GENERATING });
        const solver = new Solver({
          wordList: wordList.map(({ word }) => word),
          rowCount: rows,
          columnCount: columns,
          allowedDirections: Array.from(directions),
        }, alea(seed), favorOverlap);

        const solution = solver.placeWords();
        if (solution) {
          dispatch({ action: 'PLACE_WORDS', data: solution });
        } else {
          switch (fitHandler) {
            case FitHandler.INCREASE_SIZE:
              if (rows <= columns) {
                dispatch({ action: 'SET_ROWS', data: rows + 1 });
              }
              if (columns <= rows) {
                dispatch({ action: 'SET_COLUMNS', data: columns + 1 });
              }
              break;
            case FitHandler.REMOVE_WORD:
              dispatch({ action: 'REMOVE_WORD', data: wordList[wordList.length - 1].word });
              break;
            default:
              dispatch({ action: 'SET_STATUS', data: Status.FAILURE });
          }
        }
      }, delay);
    }
    return () => clearTimeout(timeoutId.current as NodeJS.Timeout);
  }, [rows, columns, wordList, directions, seed, fitHandler, delay, status, favorOverlap]);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
}
