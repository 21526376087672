import React, { useContext } from 'react';
import { Flex, FormLabel, Switch } from '@chakra-ui/core';
import { context } from '../../../puzzle';

export default function DisplayBorder() {
  const { state, dispatch } = useContext(context);

  return (
    <Flex justify="center" align="center">
      <FormLabel htmlFor="display-border">Display puzzle border?</FormLabel>
      <Switch
        id="display-border"
        isChecked={state.displayBorder}
        onChange={() => dispatch({ action: 'TOGGLE_DISPLAY_BORDER' })}
      />
    </Flex>
  );
}
