import React, { useContext, useState } from 'react';
import {
  Input, Box, List, ListItem, useToast, Heading,
} from '@chakra-ui/core';
import { context } from '../../puzzle';

interface WordListParams {
  columns?: number;
}

function WordList({ columns = 3 }: WordListParams) {
  const { state, dispatch } = useContext(context);
  const [entry, setEntry] = useState('');
  const toast = useToast();
  const maxLength = Math.max(state.columns, state.rows);
  const minLength = 2;

  return (
    <Box minHeight="300px">
      <Heading textAlign="center" size="sm">
Word List (
        {state.wordList.length}
)
      </Heading>
      <form
        onSubmit={(e) => {
          const words = entry.split(/[^a-zA-ZÀ-ž]{1,}/);
          const errors = words.filter((w) => w.length > maxLength || w.length < minLength);
          if (errors.length > 0) {
            errors.forEach((error) => toast({
              title: 'Word Length Error',
              description: `${error} (${error.length}) must be greater than ${minLength} and less than ${maxLength} (due to the puzzle size)`,
              status: 'error',
              duration: 3000,
              isClosable: true,
            }));
          }
          dispatch({ action: 'ADD_WORDS', data: words.filter((w) => w.length <= maxLength && w.length >= minLength) });
          setEntry('');
          e.preventDefault();
        }}
      >
        <Input
          type="text"
          variant="flushed"
          onChange={
            (e: React.ChangeEvent<HTMLInputElement>) => setEntry(e.target.value.toUpperCase())
          }
          value={entry}
          placeholder="Add Words"
          pattern="[^0-9]+"
          title="words only - no numbers; split on whitespace or punctuation"
        />
      </form>
      <List style={{ columns }}>
        {state.wordList.sort().map(({ word }, i) => (
          <ListItem
            key={i}
            cursor="pointer"
            _hover={{ textDecoration: 'line-through' }}
            onClick={() => dispatch({ action: 'REMOVE_WORD', data: word })}
          >
            {word}
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default WordList;
