import React, { useContext } from 'react';
import { Heading, Select } from '@chakra-ui/core';
import { context } from '../../../puzzle';
import FavorOverlap from '../../../FavorOverlap';

const convert = (value: string): FavorOverlap => parseInt(value, 10);

export default function FavorOverlapSelector() {
  const { state, dispatch } = useContext(context);

  return (
    <>
      <Heading size="sm" paddingBottom="2px">Overlap Letters</Heading>
      <Select
        variant="filled"
        onChange={(e) => dispatch({ action: 'SET_FAVOR_OVERLAP', data: convert(e.target.value) })}
        value={state.favorOverlap}
      >
        <option value={FavorOverlap.LESS}>Less</option>
        <option value={FavorOverlap.SOMETIMES}>Sometimes</option>
        <option value={FavorOverlap.MORE}>More</option>
      </Select>
    </>
  );
}
